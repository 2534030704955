
$body-bg: #F7F7F7;
$bg-color: #FBFBFB;
$sidebar-bg: #161616;
$nav-hover: rgba(155, 182, 255, 0.3);
$nav-active: #9bb5ff;
$white: #fff;
$white-o: rgba(255, 255, 255, 0.8);
$black: #000;
$black-1: rgba(0, 0, 0, 0.7);
$black-2: rgba(0, 0, 0, 0.8);
$gray: rgba(0, 0, 0, 0.4);
$gray-1: rgba(0, 0, 0, 0.5);
$gray-2: #999999;
$gray-3: #878787;
$gray-3: rgba(0, 0, 0, 0.6);
$red: #E11717;
$blue-button: rgba(70, 133, 206, 0.7);
$blue-button-2: rgba(70, 133, 206, 1);
$red-button: rgba(225, 23, 23, 0.7);
$red-button-2: rgba(225, 23, 23, 1);
$border-color: rgba(0, 0, 0, 0.1);
$border-color-2: rgba(0, 0, 0, 0.05);
$border-color-3: rgba(0, 0, 0, 0.15);
$light-blue:#4685CE;
$pinkish-red:#E11D48;
$nice-pink:#FF2A7B;
$hr-pink:#FD80AE;
$icon-grey:#B2B2B2;


$input-placeholder-color: #495057;

$border-radial-left: radial-gradient(circle at bottom left, $white 70%, $body-bg 30%);
$border-radial-right: radial-gradient(circle at bottom right, $white 70%, $body-bg 30%);

// Typography
// Fonts
$monserrat: 'Montserrat', sans-serif;
@font-face {
    font-family: $monserrat;
    src: 
        url('/assets/fonts/Montserrat/static/Montserrat-Thin.ttf') format("truetype"),
        url('/assets/fonts/Montserrat/static/Montserrat-SemiBold.ttf') format("truetype"),
        url('/assets/fonts/Montserrat/static/Montserrat-Regular.ttf') format("truetype"),
        url('/assets/fonts/Montserrat/static/Montserrat-Medium.ttf') format("truetype"),
        url('/assets/fonts/Montserrat/static/Montserrat-Light.ttf') format("truetype"),
        url('/assets/fonts/Montserrat/static/Montserrat-ExtraBold.ttf') format("truetype"),
        url('/assets/fonts/Montserrat/static/Montserrat-Black.ttf') format("truetype"),
        url('/assets/fonts/Montserrat/static/Montserrat-ExtraLight.ttf') format("truetype"),
        url('/assets/fonts/Montserrat/static/Montserrat-Italic.ttf') format("truetype"),
}


// Font weights
$f-weight-300: 300;
$f-weight-400: 400;
$f-weight-500: 500;
$f-weight-600: 600;

// Font sizes
$f-size-1: 2.25rem;
$f-size-2: 1.125rem;
$f-size-3: 1rem;
$f-size-4: 0.875rem;
$f-size-5: 0.75rem;
$f-size-6: 0.625rem;
$f-size-7: 1.5rem;
$f-size-8: 2rem;


// Font line height
$f-line-h: 1.3rem;
$f-line-h-1: 38.41px;
$f-line-h-2: 21.94px;
$f-line-h-3: 19.5px;
$f-line-h-4: 24.5px;
$f-line-h-5: 21px;
$f-line-h-6: 24.24px;
$f-line-h-7: 22.83px;
$f-line-h-8: 19.98px;
$f-line-h-9: 14.27px;

// Font letter spacing
$f-l-spacing: 1%;


// TRANSITIONS
$transition-default: all ease 0.3s;
$transition-1: opacity 0.3s ease;


.f-header{
    font-family: $monserrat;
    font-size: $f-size-1;
    font-weight: $f-weight-500;
    line-height: $f-line-h-1;
    letter-spacing: $f-l-spacing;
}

.f-header-2{
    font-family: $monserrat;
    font-size: $f-size-7;
    font-weight: $f-weight-500;
    line-height: $f-line-h-1;
}

.f-menu-link{
    font-family: $monserrat;
    font-size: $f-size-2;
    font-weight: $f-weight-300;
    line-height: $f-line-h-6;
}
.nav-item {
    .active{
        font-weight: $f-weight-400;
    }
}


.f-sub-header-1{
    font-family: $monserrat;
    font-size: $f-size-2;
    font-weight: $f-weight-400;
    line-height: $f-line-h-2;
}

.f-sub-header-2{
    font-family: $monserrat;
    font-size: $f-size-3;
    font-weight: $f-weight-600;
    line-height: $f-line-h-3;
}
.f-sub-header-3{
    font-family: $monserrat;
    font-size: $f-size-3;
    font-weight: $f-weight-500;
    line-height: $f-line-h-3;
}

.f-sub-title-1{
    font-size: $f-size-2;
    font-family: $monserrat;
    line-height: $f-line-h-6;
    font-weight: $f-weight-600;
}

.f-body-1{
    font-family: $monserrat;
    font-size: $f-size-4;
    font-weight: $f-weight-500;
    line-height: $f-line-h-4;
}

.f-body-2{
    font-family: $monserrat;
    font-size: $f-size-5;
    font-weight: $f-weight-500;
    line-height: $f-line-h-5;
}

.f-body-3{
    font-family: $monserrat;
    font-size: $f-size-5;
    font-weight: $f-weight-400;
    line-height: $f-line-h-8;
}

.f-body-4 {
    font-family: $monserrat;
    font-size: $f-size-3;
    font-weight: $f-weight-400;
    line-height: $f-line-h-4;
    letter-spacing: $f-l-spacing;
}

.f-body-5{
    font-family: $monserrat;
    font-size: $f-size-4;
    font-weight: $f-weight-400;
    line-height: $f-line-h-8;
}

.f-info{
    font-family: $monserrat;
    font-size: $f-size-6;
    font-weight: $f-weight-500;
    line-height: $f-line-h-9;
}

.f-button{
    font-family: $monserrat;
    font-size: $f-size-3;
    font-weight: $f-weight-600;
    line-height: $f-line-h-7;
}

.f-button:hover{
    text-decoration: none;
}

.f-button-2{
    font-family: $monserrat;
    font-size: $f-size-5;
    font-weight: $f-weight-600;
    line-height: $f-line-h-7;
}
.f-button-2:hover{
    text-decoration: none;
}

.font-black-1{
    color: $black-1;
}
.font-black{
    color: $black;
}
.font-white{
    color: $white;
}
.font-red{
    color: $red;
}

.font-gray{
    color: $gray;
}

.font-weight-1{
    font-weight: $f-weight-400;
}

.font-weight-2{
    font-weight: $f-weight-500;
}

.font-weight-3{
    font-weight: $f-weight-600;
}

.font-blue{
    color: $blue-button;
}


.f-link, .f-text-1{
    font-family: $monserrat;
    font-weight: $f-weight-400;
    font-size: $f-size-4;
    line-height: $f-line-h;
}

.f-header-3{
    font-family: $monserrat;
    font-size: $f-size-8;
    font-weight: $f-weight-600;
    line-height: $f-line-h;
}

.f-link:hover{
    text-decoration: none;
}

.font-light-blue{
    color: $light-blue;
}

.font-pinkish-red{
    color: $pinkish-red;
}