// @import "./variables";

.main-loader{
    position: fixed;
    left: 300px;
    right: 0;
    top: 0;
    bottom: 0;
    background: $white-o;
    width: 100%;
    height: 100% !important;
    z-index: 9999;
    opacity: 1;
    visibility: visible;
    transition: $transition-default;
    .center-loader{
        position: fixed;
        width: 65px;
        top: 50%;
        left: calc(50% + 300px);
        transform: translate(calc(-50% - 150px), -50%);
    }
    &.loaded{
        opacity: 0;
        visibility: hidden;
        z-index: -99;
    }
}

#main{
    margin-left: 0;
    background: $body-bg;
    opacity: 0;
    visibility: hidden;
    transition: none;
    margin-left: 300px;
    .container-fluid{
        padding: 0 60px;
    }
    .navbar{
        background: $body-bg;
        border: none;
    }
    &.loaded{
        opacity: 1;
        visibility: visible;
    }
}

*:focus {
    outline: none;
}

.sidebar-lg-show{
    #main{
        min-height: 100vh;
        &.logged-out{
            margin-left: 0;
            transition: none;
        }
    }
}

.sidebar-minimized{
    #main{
        margin-left: 50px;
    }
}

.add-dialog-content{
    border: none;
    .body-content{
        display: flex;
        .modal-profile{
            width: 40%;
            padding-right: 60px;
            margin-top: 15%;
            .filepond--drop-label label{
                width: 100%;
            }
            .add-avatar-wrapper.logo{
                width: 160px;
                height: 160px;
                display: block;
                overflow: hidden;
                margin: auto;
                opacity: 1;
                -webkit-transition: $transition-default;
                -moz-transition: $transition-default;
                -ms-transition: $transition-default;
                -o-transition: $transition-default;
                transition: $transition-default;
                img{
                    width: 100%;
                    border-radius: 50%;
                    object-fit: cover;
                    min-width: 160px;
                    min-height: 160px;
                    border: 1px solid rgba(0, 0, 0, 0.001);
                    cursor: pointer;
                }
                .filepond--drop-label.filepond--drop-label label{
                    padding: 0;
                    cursor: pointer;
                }
                &:hover{
                    opacity: 0.7;
                }
            }
            .add-logo-wrapper.logo{
                width: 160px;
                height: 160px;
                overflow: hidden;
                margin: auto;
                opacity: 1;
                -webkit-transition: $transition-default;
                -moz-transition: $transition-default;
                -ms-transition: $transition-default;
                -o-transition: $transition-default;
                transition: $transition-default;
                .filepond--root, 
                .filepond--root .filepond--image-preview-wrapper{
                    border-radius: 10px;
                }
                img{
                    width: 100%;
                    object-fit: cover;
                    min-width: 160px;
                    min-height: 160px;
                    border: 1px solid rgba(0, 0, 0, 0.001);
                    cursor: pointer;
                }
                .filepond--drop-label.filepond--drop-label label{
                    padding: 0;
                    cursor: pointer;
                }
                &:hover{
                    opacity: 0.7;
                }
            }
        }
    }
}

.edit-dialog-content{
    border: none;
    .body-content{
        display: flex;
        .modal-profile{
            width: 40%;
            padding-right: 60px;
            margin-top: 15%;
            .filepond--drop-label label{
                width: 100%;
            }
            .add-avatar-wrapper.logo{
                width: 160px;
                height: 160px;
                display: block;
                overflow: hidden;
                margin: auto;
                opacity: 1;
                -webkit-transition: $transition-default;
                -moz-transition: $transition-default;
                -ms-transition: $transition-default;
                -o-transition: $transition-default;
                transition: $transition-default;
                img{
                    width: 100%;
                    border-radius: 50%;
                    object-fit: cover;
                    min-width: 160px;
                    min-height: 160px;
                    border: 1px solid rgba(0, 0, 0, 0.001);
                    cursor: pointer;
                }
                .filepond--drop-label.filepond--drop-label label{
                    padding: 0;
                    cursor: pointer;
                }
                &:hover{
                    opacity: 0.7;
                }
            }
            .add-logo-wrapper.logo{
                width: 160px;
                height: 160px;
                overflow: hidden;
                margin: auto;
                opacity: 1;
                -webkit-transition: $transition-default;
                -moz-transition: $transition-default;
                -ms-transition: $transition-default;
                -o-transition: $transition-default;
                transition: $transition-default;
                .filepond--root, 
                .filepond--root .filepond--image-preview-wrapper{
                    border-radius: 10px;
                }
                img{
                    width: 100%;
                    object-fit: cover;
                    min-width: 160px;
                    min-height: 160px;
                    border: 1px solid rgba(0, 0, 0, 0.001);
                    cursor: pointer;
                }
                .filepond--drop-label.filepond--drop-label label{
                    padding: 0;
                    cursor: pointer;
                }
                &:hover{
                    opacity: 0.7;
                }
            }
        }
    }

    .mat-form-field{
        width: 100%;
    }
}

.small-modal{
    .modal-dialog{
        max-width: 430px;
    }
    .modal-content{
        padding: 30px 20px;
        .modal-body{
            padding: 30px 0;
            .form-text{
                margin: 0;
            }
            .modal-info{
                max-width: 80%;
                margin: auto;
            }
        }
        .modal-footer{
            border: none;
            justify-content: center;
            padding: 0;
            margin-bottom: 30px;
            .button-wrapper{
                .cancel-button{
                    padding: 0;
                    margin: 0 30px;
                    background: transparent;
                    border: none;
                    color: $blue-button;
                    transition: $transition-default;
                }
                .save-button, .add-button, .edit-button{
                    padding: 0;
                    margin: 0 30px;
                    background: transparent;
                    border: none;
                    color: $blue-button;
                    transition: $transition-default;
                }
                .remove-button{
                    padding: 0;
                    margin: 0 30px;
                    background: transparent;
                    border: none;
                    color: $red-button;
                    transition: $transition-default;
                }
                .save-button:focus, .add-button:focus, .cancel-button:focus, .remove-button:focus{
                    outline: none;
                }
                .save-button:hover, .add-button:hover{
                    color: $blue-button-2;
                }
                .cancel-button:hover{
                    color: $blue-button-2;
                }
                .remove-button:hover{
                    color: $red-button-2;
                }
            }
        }
    }
}

.small-modalbox{
    .modal-dialog{
        max-width: 430px;
        margin-top: 0;
    }
    .modal-content{
        padding: 30px 20px;
        border: none;
        .modal-body{
            padding: 30px 0;
            .form-text{
                margin: 0;
            }
            .modal-info{
                max-width: 80%;
                margin: auto;
            }
        }
        .modal-footer{
            border: none;
            justify-content: center;
            padding: 0;
            margin-bottom: 30px;
            .button-wrapper{
                .cancel-button{
                    padding: 0;
                    margin: 0 30px;
                    background: transparent;
                    border: none;
                    color: $blue-button;
                    transition: $transition-default;
                }
                .save-button, .add-button, .edit-button{
                    padding: 0;
                    margin: 0 30px;
                    background: transparent;
                    border: none;
                    color: $blue-button;
                    transition: $transition-default;
                }
                .remove-button{
                    padding: 0;
                    margin: 0 30px;
                    background: transparent;
                    border: none;
                    color: $red-button;
                    transition: $transition-default;
                }
                .save-button:focus, .add-button:focus, .cancel-button:focus, .remove-button:focus{
                    outline: none;
                }
                .save-button:hover, .add-button:hover{
                    color: $blue-button-2;
                }
                .cancel-button:hover{
                    color: $blue-button-2;
                }
                .remove-button:hover{
                    color: $red-button-2;
                }
            }
        }
    }
}

.for--modal{
    font-family:  $monserrat;
    font-weight: $f-weight-500;
    font-size: $f-size-4;
    line-height: 1.3em;
    text-align: center;
    letter-spacing: 0.01em;
    color: $black-1;
}

.modal-avatar--label{
    text-align: center;
    p{
        font-family:  $monserrat;
        font-weight: $f-weight-500;
        font-size: $f-size-5;
        line-height: 1.3em;
        text-align: center;
        color: $black-2;
        margin-bottom: 5px;
    }
}

.modal-maxsize--info{
    text-align: center;
    p{
        font-family:  $monserrat;
        font-weight: $f-weight-400;
        font-size: $f-size-6;
        line-height: 1.3em;
        text-align: center;
        letter-spacing: 0.01em;
        color: $black-1;
        margin-bottom: 5px;
        font-style: italic;
    }
}

#newKeyContactToRepModal{
    .modal-dialog{
        max-width: 400px;
        .modal-content{
            border: none;
            border-radius: 10px;
            .modal-header{
                border: none;
                padding: 40px;
                h5{
                    width: 100%;
                    text-align: center;
                    font-family: $monserrat;
                    font-size: $f-size-2;
                    font-weight: $f-weight-600;
                    line-height: $f-line-h-6;
                    padding-top: 10px;
                    pointer-events: none;
                }
                .close{
                    font-size: 2.5rem;
                    font-weight: $f-weight-400;
                    &:focus{
                        outline: none;
                    }
                }
            }
            .modal-body{
                padding: 0 30px;
                border: none;
                form{
                    display: block;
                    width: 100%;
                    max-width: 100%;
                }
                .body-content{
                    .modal-info{
                        width: 100%;
                        .form-group{
                            margin-bottom: 20px;
                            input {
                                height: 52px;
                                border: 1px solid $border-color-3;
                                box-sizing: border-box;
                                border-radius: 10px;
                            }
                            label{
                                font-family: $monserrat;
                                font-size: $f-size-5;
                                font-weight: $f-weight-500;
                                line-height: $f-line-h-3;
                                margin-left: 15px;
                                margin-bottom: 5px;
                                display: block;
                                color: $black-2;
                            }
                            .positions-list--center{
                                position: relative;
                                .select2 {
                                    .select2-selection{
                                        padding: 3px 5px 7px 5px;
                                        border: 1px solid $border-color-3;
                                        box-sizing: border-box;
                                        border-radius: 10px;
                                        min-height: 52px;
                                        display: flex;
                                        align-items: center;
                                        .select2-selection__rendered{
                                            .select2-selection__choice{
                                                background: rgba(155, 182, 255, 0.4);
                                                border-radius: 5px;
                                                border: transparent;
                                                padding: 7px 20px 7px 20px;
                                                position: relative;
                                                border-radius: 5px;
                                                font-size: $f-size-3;
                                                font-weight: $f-weight-400;
                                                line-height: normal;
                                                .select2-selection__choice__remove{
                                                    position: absolute;
                                                    right: 5px;
                                                    top: 0px;
                                                    color: #8A96B8;
                                                }
                                            }
                                            .select2-search{
                                                .select2-search__field{
                                                    border: none;
                                                    height: auto;
                                                    margin: 0;
                                                    width: 100% !important;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .modal-footer{
                border: none;
                padding: 20px 30px 30px 30px;
                .button-wrapper{
                    width: 100%;
                    margin-left: auto;
                    text-align: center;
                    .save-button, .edit-button{
                        background: $white;
                        border: none;
                        font-family: $monserrat;
                        font-size: $f-size-3;
                        font-weight: $f-weight-600;
                        line-height: $f-line-h-2;
                        color: $gray-2;
                    }
                    .save-button:focus, .edit-button{
                        outline: none;
                    }
                }
            }
        }
    }
}

.single-services, 
.single-industries{
    .select2 {
        opacity: 0;
        width: 100%;
        position: absolute;
        top: -5px;
        z-index: -1;
    }

    .select2.showInput {
        opacity: 1;
        width: 100%;
        position: absolute;
        top: -5px;
        z-index: 2;
        -webkit-transition: $transition-1;
        -moz-transition: $transition-1;
        -ms-transition: $transition-1;
        -o-transition: $transition-1;
        transition: $transition-1;
        .select2-selection {
            padding: 10px 15px 7px 15px;
            border: 1px solid $border-color-3;
            box-sizing: border-box;
            border-radius: 5px;

            ul {
                padding: 0;
                text-transform: capitalize;

                li.select2-selection__choice{
                    background: rgba(70, 133, 206, 0.1);
                    border-radius: 2px;
                    border: transparent;
                    padding: 7px 20px 7px 20px;
                    position: relative;
                    border-radius: 5px;
                    font-size: $f-size-3;
                    font-weight: $f-weight-400;
                    line-height: normal;
                    .select2-selection__choice__remove {
                        position: absolute;
                        right: 5px;
                        top: 2px;
                        color: #D6D6D6;
                    }
                }
            }
        }
    }
}

.person-positions{
    .select2 {
        opacity: 0;
        width: 100%;
        position: absolute;
        top: -5px;
        z-index: -1;
    }

    .select2.showInput {
        opacity: 1;
        width: 100%;
        position: absolute;
        top: -5px;
        z-index: 2;
        -webkit-transition: $transition-1;
        -moz-transition: $transition-1;
        -ms-transition: $transition-1;
        -o-transition: $transition-1;
        transition: $transition-1;
        .select2-selection {
            padding: 10px 15px 7px 15px;
            border: 1px solid $border-color-3;
            box-sizing: border-box;
            border-radius: 5px;

            ul {
                padding: 0;
                text-transform: capitalize;

                li.select2-selection__choice{
                    background: rgba(155, 182, 255, 0.4);
                    border-radius: 2px;
                    border: transparent;
                    padding: 7px 20px 7px 20px;
                    position: relative;
                    border-radius: 5px;
                    font-size: $f-size-3;
                    font-weight: $f-weight-400;
                    line-height: normal;
                    .select2-selection__choice__remove {
                        position: absolute;
                        right: 5px;
                        top: 2px;
                        color: #8A96B8;
                    }
                }
            }
        }
    }
}

.select-form .select2 .select2-selection {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0px 15px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    height: 52px !important;
    border: 1px solid $border-color-3;
    box-sizing: border-box;
    border-radius: 10px;
    .select2-selection__rendered{
        display: flex;
        align-items: center;
        padding-left: 0;

        .flag-text{
            font-family: $monserrat;
            font-size: $f-size-3;
            font-weight: $f-weight-500;
            line-height: $f-line-h-7;
            padding-left: 20px;
            color: #000;
            height: 27px;
            display: flex;
            align-items: center;
            position: relative;
            &::before{
                content: "";
                position: absolute;
                left: 10px;
                width: 0;
                height: 22px;
                border-left: 1px solid #C4C4C4;

            }
        }
    }
    .select2-selection__arrow{
        height: auto;
        position: absolute;
        top: 50%;
        right: 15px;
        width: 19px;
        height: 8px;
        transform: translateY(-50%);
        b{
            border-color: unset;
            border-style: unset;
            border-width: unset;
            height: 8px;
            left: unset;
            margin-left:unset;
            margin-top: unset;
            position: inherit;
            top: unset;
            width: 19px;
            // background-image: url(../img/arrow-down.svg);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            -webkit-transform: rotate(0);
            -moz-transform: rotate(0);
            -ms-transform: rotate(0);
            -o-transform: rotate(0);
            transform: rotate(0);
            transition: $transition-default;
        }
    }
}

.select-form .select2.select2-container--open{
    .select2-selection__arrow{
        height: auto;
        position: absolute;
        top: 50%;
        right: 15px;
        width: 19px;
        height: 8px;
        transform: translateY(-50%);
        b{
            border-color: unset;
            border-style: unset;
            border-width: unset;
            height: 8px;
            left: unset;
            margin-left:unset;
            margin-top: unset;
            position: inherit;
            top: unset;
            width: 19px;
            // background-image: url(../img/arrow-down.svg);
            -webkit-transform: rotate(-90deg);
            -moz-transform: rotate(-90deg);
            -ms-transform: rotate(-90deg);
            -o-transform: rotate(-90deg);
            transform: rotate(-90deg);
        }
    }
}

.select2-dropdown .select2-results .select2-results__option{
    display: flex;
    align-items: center;

    .flag-text {
        padding-left: 10px;
        font-family: $monserrat;
        font-size: $f-size-3;
        font-weight: $f-weight-500;
        line-height: $f-line-h-7;
        color: $gray-1;
    } 
}

.select2-results__message {
    display: none !important;
}

.single-container{
    .add{
        opacity: 0;
        transition: $transition-default;
    }

    &:hover{
        .add{
            opacity: 1;
        }
    }
}

.tippy-box{
    width: 335px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgb(0 0 0 / 15%);
    border-radius: 10px;
    .tippy-arrow{
        display: none;
    }
    .tippy-content {
        padding: 20px;
        color: $black;
        .tooltip-header{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .button{
                a{
                    font-family: $monserrat;
                    font-weight: $f-weight-500;
                    font-size: $f-size-5;
                    line-height: 17.12px;
                    color: $blue-button;
                    margin-bottom: 0;
                    text-align: center;
                    display: contents;
                    cursor: pointer;
                }
            }
            .close-button{
                text-align: right;
                padding-bottom: 10px;
                img{
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    cursor: pointer;
                }
            }
        }
        .tooltip-avatar{
            width: 75px;
            height: 75px;
            min-width: 75px;
            min-height: 75px;
            margin: auto;
            border-radius: 100%;
            overflow: hidden;
            border: 0.5px solid $border-color-3;
            img{
                width: 100%;
                height: 100%;
                min-width: 75px;
                min-height: 75px;
                object-fit: cover;
            }
        }
        .tooltip-logo{
            width: 75px;
            height: 75px;
            min-width: 75px;
            min-height: 75px;
            margin: auto;
            border-radius: 10px;
            overflow: hidden;
            border: 0.5px solid $border-color-3;
            img{
                width: 100%;
                height: 100%;
                min-width: 75px;
                min-height: 75px;
                object-fit: cover;
            }
        }
        .tooltip-info{
            position: relative;
            padding-left: 10px;
            padding-right: 10px;
            text-align: left;
            padding-bottom: 15px;
            .name{
                text-align: center;
                padding-top: 20px;
                font-size: $f-size-2;
                font-family: $monserrat;
                font-weight: $f-weight-500;
                line-height: $f-line-h-7;
                color: #000;
                margin-bottom: 20px;
            }
            h3{
                font-size: $f-size-3;
                font-family: $monserrat;
                font-weight: $f-weight-400;
                line-height: $f-line-h-3;
                color: #000;
                margin-bottom: 20px;
                display: inline;
                span{
                    color: $gray-1;
                    font-size: $f-size-3;
                    font-family: $monserrat;
                    font-weight: $f-weight-400;
                    font-style: italic;
                } 
            }
            p{
                font-family: $monserrat;
                font-weight: $f-weight-400;
                font-size: $f-size-5;
                line-height: 17.12px;
                color: $gray;
                margin-bottom: 0;
            }
            .email{
                margin: 7px 0;
            }
        }
        hr{
            border-top: 1px solid $hr-pink;
            margin: 0;
        }
        .tooltip-address-info{
            padding: 20px 10px 0 10px;
            position: relative;
            .location{
                width: 100%;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                text-align: left;
                .address {
                    width: 65%;
                    padding-right: 3%;
                    h4{
                        font-family: $monserrat;
                        font-weight: $f-weight-500;
                        font-size: $f-size-5;
                        line-height: 17.12px;
                        color: #000;
                        margin-bottom: 0;
                        span{
                            color: $gray-1;
                            font-size: $f-size-3;
                            font-family: $monserrat;
                            font-weight: $f-weight-400;
                            font-style: italic;
                        } 
                    }
                }

                .country{
                    width: 32%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-wrap: wrap;
                    img{
                        border-radius: 100%;
                        width: 13px;
                        height: 13px;
                        object-fit: cover;
                    }
                    h3{
                        font-size: $f-size-3;
                        font-family: $monserrat;
                        font-weight: $f-weight-500;
                        line-height: 1.3em;
                        color: #000;
                        padding-left: 5px;
                        margin-bottom: 0;
                        span{
                            color: $gray-1;
                            font-size: $f-size-3;
                            font-family: $monserrat;
                            font-weight: $f-weight-400;
                            font-style: italic;
                        } 
                    }
                }

            }
            .socials{
                display: flex;
                display: flex;
                justify-content: center;
                margin: 30px 0;
                .icon-wrapper{
                    height: 33px;
                    width: 33px;
                    border-radius: 100%;
                    background: $body-bg;
                    position: relative;
                    margin: 0 6px;
                    &:first-child{
                        margin-left: 0;
                    }
                    a{
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50% , -50%);
                        width: 17px;
                        height: 17px;
                        img{
                            vertical-align: inherit;
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }
                .icon-wrapper.hidden{
                   opacity: .3;
                   pointer-events: none;
                }
            }
            .tool-editProfile{
                position: relative;
                text-align: center;
                a{
                    font-family: $monserrat;
                    font-weight: $f-weight-500;
                    font-size: $f-size-5;
                    line-height: 17.12px;
                    color: $blue-button;
                    margin-bottom: 0;
                    text-align: center;
                    display: contents;
                    cursor: pointer;
                }
            }
        }

        .no-information{
            color: $gray-1;
            font-size: 0.8rem;
            font-family: $monserrat;
            font-weight: 400;
            font-style: italic;
        }
    }
}

// .sidenav-info.show{
//     width: 25%;
//     z-index: 9999;
//     transform: translateX(0%);
// }
.mat-drawer-transition .mat-drawer-backdrop{
    z-index: -1 !important;
  }
  .sidenav-info.show {
    width: 25%;
    z-index: 99999;
    transform: translateX(0%);
  }
    
.sidenav-info {
    height: 100%;
    width: 0;
    transform: translateX(100%);
    position: fixed;
    z-index: 2;
    top: 0;
    right: 0;
    background: #FFFFFF;
    box-shadow: -4px 0px 30px rgba(0, 0, 0, 0.1);
    overflow-x: hidden;
    transition: all ease 0.3s;
    .sidenav-info-wrapper{
        padding: 20px 60px;
        width: 450px;
        .close-button{
            text-align: right;
            padding-bottom: 30px;
            img{
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                cursor: pointer;
            }
        }
        .navmenu-avatar{
            width: 160px;
            height: 160px;
            min-width: 160px;
            min-height: 160px;
            margin: auto;
            border-radius: 100%;
            overflow: hidden;
            border: 0.5px solid $border-color-3;
            img{
                width: 100%;
                height: 100%;
                min-width: 160px;
                min-height: 160px;
                object-fit: cover;
            }
        }
        .navmenu-logo{
            width: 160px;
            height: 160px;
            min-width: 160px;
            min-height: 160px;
            margin: auto;
            border-radius: 10px;
            overflow: hidden;
            border: 0.5px solid $border-color-3;
            img{
                width: 100%;
                height: 100%;
                min-width: 160px;
                min-height: 160px;
                object-fit: cover;
            }
        }
        .navmenu-info{
            position: relative;
            .name{
                text-align: center;
                padding-top: 20px;
                p{
                    font-family: $monserrat;
                    font-weight: $f-weight-500;
                    font-size: $f-size-5;
                    line-height: 17.12px;
                    color: $black-2;
                    margin-bottom: 30px;
                }
                h3{
                    font-size: $f-size-2;
                    font-family: $monserrat;
                    font-weight: $f-weight-600;
                    line-height: $f-line-h-6;
                    color: $black;
                    span{
                        color: $gray-1;
                        font-size: $f-size-2;
                        font-family: $monserrat;
                        font-weight: $f-weight-400;
                        font-style: italic;
                    } 
                }
            }
            .position{
                margin-bottom: 30px;
            }
            .position, .email, .phone, .website{
                h3{
                    font-size: $f-size-2;
                    font-family: $monserrat;
                    font-weight: $f-weight-400;
                    line-height: $f-line-h-6;
                    color: $black;
                    margin-bottom: 30px;
                    display: inline;
                    span{
                        color: $gray-1;
                        font-size: $f-size-2;
                        font-family: $monserrat;
                        font-weight: $f-weight-400;
                        font-style: italic;
                    }
                }
                a{
                    display: block;
                    font-size: $f-size-2;
                    font-family: $monserrat;
                    font-weight: $f-weight-400;
                    line-height: $f-line-h-6;
                    color: $black;
                    margin-bottom: 30px;  
                }
                p{
                    font-family: $monserrat;
                    font-weight: $f-weight-500;
                    font-size: $f-size-5;
                    line-height: 17.12px;
                    color: $gray;
                    margin-bottom: 0;
                }
            }
           
        }
        .navmenu-address-info{
            .address{
                .country{
                    display: flex;
                    align-items: center;
                    margin-top: 8px;
                    h3{
                        font-size: $f-size-2;
                        font-family: $monserrat;
                        font-weight: $f-weight-400;
                        line-height: $f-line-h-6;
                        color: $black;
                        margin-bottom: 0;
                        padding-left: 10px;
                        span{
                            color: $gray-1;
                            font-size: $f-size-2;
                            font-family: $monserrat;
                            font-weight: $f-weight-400;
                            font-style: italic;
                        } 
                    }
                    img{
                        width: 28px;
                        height: 28px;
                        object-fit: cover;
                        border-radius: 100px;
                    }
                }

                h4{
                    font-size: $f-size-2;
                    font-family: $monserrat;
                    font-weight: $f-weight-400;
                    line-height: $f-line-h-6;
                    color: $black;
                    margin-bottom: 0;  
                    span{
                        color: $gray-1;
                        font-size: $f-size-2;
                        font-family: $monserrat;
                        font-weight: $f-weight-400;
                        font-style: italic;
                    } 
                }
                p{
                    font-family: $monserrat;
                    font-weight: $f-weight-500;
                    font-size: $f-size-5;
                    line-height: 17.12px;
                    color: $gray;
                    margin-bottom: 0;
                }
            }

            .socials{
                display: block;
                margin-bottom: 30px;
                h3{
                    font-size: $f-size-3;
                    font-family: $monserrat;
                    font-weight: $f-weight-500;
                    line-height: $f-line-h-7;
                    color: $black;
                    padding-top: 60px;
                    padding-bottom: 30px;
                }
                .social-item{
                    display: block;
                    margin-bottom: 30px;
                    p{
                        padding-left: 65px;
                        font-family: $monserrat;
                        font-weight: $f-weight-500;
                        font-size: $f-size-5;
                        line-height: 17.12px;
                        color: $gray;
                        margin-bottom: 0;
                    }
                    &__row{
                        display: flex;
                        align-items: center;
                        .icon-wrapper{
                            height: 40px;
                            width: 40px;
                            min-width: 40px;
                            min-height: 40px;
                            border-radius: 100%;
                            background: $body-bg;
                            position: relative;
                            margin-right: 20px;
                            &__row{
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50% , -50%);
                                width: 17px;
                                height: 17px;
                                min-width: 17px;
                                min-height: 17px;
                                img{
                                    vertical-align: inherit;
                                    width: 100%;
                                    height: 100%;
                                    object-fit: contain;
                                }
                            }
        
        
                        }
                        a{
                            font-size: $f-size-3;
                            font-family: $monserrat;
                            font-weight: $f-weight-400;
                            line-height: $f-line-h-7;
                            color: $black;
                            cursor: pointer;
                            white-space: nowrap;
                            width: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }

            }
            .button{
                position: relative;
                text-align: center;
                a{
                    font-family: $monserrat;
                    font-weight: $f-weight-500;
                    font-size: $f-size-5;
                    line-height: 17.12px;
                    color: $blue-button;
                    margin-bottom: 0;
                    text-align: center;
                    display: contents;
                }
            }
        }
    }
    .no-information{
        color: $gray-1;
        font-size: 1rem;
        font-family: $monserrat;
        font-weight: 400;
        font-style: italic;
        margin-bottom: 30px;
        display: block;
    }
}

.add-new-client.isnot-client{
    position: relative;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    .client-wrapper.client-added{
        margin: 0 20px;
        visibility: hidden;
        .icon{
            width: 35px;
            height: 35px;
            border-radius: 100%;
            background: linear-gradient(
                222.66deg, #FD80AE -22.94%, #FF2A7B 102.11%);
            text-align: center;
            margin: auto;
            padding: 7px 10px;
            cursor: pointer;
        }
    }

    .client-wrapper.client-isnot{
        margin: 0 20px;
        visibility: visible;
        position: absolute;
        left: 0;
        .icon{
            width: 35px;
            height: 35px;
            border-radius: 100%;
            background: rgba(0, 0, 0, 0.3);
            text-align: center;
            margin: auto;
            padding: 7px 10px;
            cursor: pointer;
        }
    }
}

.add-new-client.is-client{
    position: relative;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    .client-wrapper.client-added{
        margin: 0 20px;
        visibility: visible;
        position: absolute;
        left: 0;
        .icon{
            width: 35px;
            height: 35px;
            border-radius: 100%;
            background: linear-gradient(
                222.66deg, #FD80AE -22.94%, #FF2A7B 102.11%);
            text-align: center;
            margin: auto;
            padding: 7px 10px;
            cursor: pointer;
        }
    }

    .client-wrapper.client-isnot{
        margin: 0 20px;
        visibility: hidden;
        .icon{
            width: 35px;
            height: 35px;
            border-radius: 100%;
            background: rgba(0, 0, 0, 0.3);
            text-align: center;
            margin: auto;
            padding: 7px 10px;
            cursor: pointer;
        }
    }
}

// .settings{
//     align-self: center;
//     position: absolute;
//     right: 90px;
//     .settings-wrapper{
//         position: relative;
//         .settings-button{
//             display: flex;
//             width: max-content;
//             color: $black;
//             img{
//                 height: fit-content;
//                 margin: auto;
//                 vertical-align: top;
//                 -webkit-user-select: none;
//                 -khtml-user-select: none;
//                 -moz-user-select: none;
//                 -o-user-select: none;
//                 user-select: none;
//             }
//             &:hover{
//                 text-decoration: none;
//             }
//         }
//         .settings-tooltip{
//             position: absolute;
//             background: white;
//             width: max-content;
//             min-width: 235px;
//             right: 0;
//             top: 40px;
//             border-radius: 10px;
//             padding: 5px;
//             box-shadow: 0px 0px 20px rgb(0 0 0 / 15%);
//             opacity: 0;
//             z-index: 0;
//             visibility: hidden;
//             -webkit-transform: scaleY(0);
//             -moz-transform: scaleY(0);
//             -o-transform: scaleY(0);
//             transform: scaleY(0);
//             transform-origin: top;
//             -webkit-transition: $transition-default;
//             -moz-transition: $transition-default;
//             -o-transition: $transition-default;
//             transition: $transition-default;
//             a{
//                 display: list-item;
//                 margin: 10px 25px;
//                 list-style: none;
//             }
//             a:hover{
//                 text-decoration: none;
//                 color: $black;
//             }
//             .settings-delete{
//                 margin: 10px 10px;
//                 padding: 10px 0 0 15px;
//                 border-top: 1px solid rgba(0, 0, 0, 0.1);
//                 color: $red;
//                 font-weight: 500;
//                 background: transparent;
//                 border-bottom: 0;
//                 text-align: left;
//                 border-right: 0;
//                 border-left: 0;
//             }
//             &:hover{
//                 text-decoration: none;
//                 color: $black;
//             }
//             .permission{
//                 position: relative;
//                 border-bottom: 1px solid $border-color-2;
//                 margin: 10px 5px;
//                 padding: 0 20px;
//                 label{
//                     margin-bottom: 5px;
//                     cursor: pointer;
//                 }
//                 input{
//                     position: absolute;
//                     right: 20px;
//                     opacity: 0;
//                     height: 0;
//                     width: 0;
//                 }
//                 .checkmark {
//                     position: absolute;
//                     right: 20px;
//                     height: 13px;
//                     width: 10px;
//                     background-color: $white;
//                 }
//                 .checkmark:after {
//                     content: "";
//                     position: absolute;
//                     display: none;
//                 }
//                 input:checked ~ .checkmark:after {
//                     display: block;
//                 }
//                 .checkmark:after {
//                     left: 12px;
//                     top: 0;
//                     width: 9px;
//                     height: 15px;
//                     border: solid $white;
//                     border-width: 0 3px 3px 0;
//                     -webkit-transform: rotate(45deg);
//                     -ms-transform: rotate(45deg);
//                     transform: rotate(45deg);
//                 }
//                 input:checked ~ .checkmark:after {
//                     border-color: $blue-button;
//                 }
//             }
//             &.active{
//                 opacity: 1;
//                 z-index: 1;
//                 visibility: visible;
//                 -webkit-transform: scaleY(1);
//                 -moz-transform: scaleY(1);
//                 -o-transform: scaleY(1);
//                 transform: scaleY(1);
//             }
//         }
//     }
// }

// .rating-loading {
//     width: 25px;
//     height: 25px;
//     font-size: 0;
//     color: #fff;
//     background: url(../img/loading.gif) top left no-repeat;
//     border: none;
//   }
  .star-raiting--section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    &:hover{
        transition-duration: 1s;
        .star-raiting__all-raiting{
            visibility: visible;
            z-index: 3;
        }
    }

    .star-raiting__all-raiting{
        visibility: hidden;
        position: absolute;
        top: 60px;
        left: 50%;
        transform: translateX(-50%);
        width: 220px;
        -webkit-transition: all 800ms ease-in;
        -moz-transition: all 800ms ease-in;
        -ms-transition: all 800ms ease-in;
        -o-transition: all 800ms ease-in;
        transition: all 800ms ease-in;
    }

    .star-raiting__avg{
        display: flex;
        position: relative;
        align-items: center;

        .average-raiting{
            margin-bottom: 0;
            position: absolute;
            left: -45px;
            width: 40px;
            text-align: right;
            font-family: $monserrat;
            font-size: $f-size-2;
            color: $gray-3;
            font-weight: $f-weight-400;
            line-height: 30px;
        }
    }
  }


  .rating-loading {
    width: 25px;
    height: 25px;
    font-size: 0;
    color: #fff;
    // background: url(../img/loading.gif) top left no-repeat;
    border: none;
  }
  
  .rating-container {
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    .rating-stars {
      position: relative;
      cursor: pointer;
      vertical-align: middle;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
    }
    .rating-input {
      position: absolute;
      cursor: pointer;
      width: 100%;
      height: 1px;
      bottom: 0;
      left: 0;
      font-size: 1px;
      border: none;
      background: 0 0;
      padding: 0;
      margin: 0;
    }
  }
  
  .rating-disabled {
    .rating-input, .rating-stars {
      cursor: not-allowed;
    }
  }
  
  .rating-container {
    .star {
      display: inline-block;
      margin: 0 3px;
      text-align: center;
    }
    .empty-stars {
      color: #aaa;
    }
    .filled-stars {
      position: absolute;
      left: 0;
      top: 0;
      margin: auto;
      color: #fde16d;
      white-space: nowrap;
      overflow: hidden;
      -webkit-text-stroke: 1px #777;
      text-shadow: 1px 1px #999;
    }
  }
  
  .rating-rtl {
    float: right;
  }
  
  .rating-animate .filled-stars {
    transition: width .25s ease;
    -o-transition: width .25s ease;
    -moz-transition: width .25s ease;
    -webkit-transition: width .25s ease;
  }
  
  .rating-rtl {
    .filled-stars {
      left: auto;
      right: 0;
      -moz-transform: matrix(-1, 0, 0, 1, 0, 0) translate3d(0, 0, 0);
      -webkit-transform: matrix(-1, 0, 0, 1, 0, 0) translate3d(0, 0, 0);
      -o-transform: matrix(-1, 0, 0, 1, 0, 0) translate3d(0, 0, 0);
      transform: matrix(-1, 0, 0, 1, 0, 0) translate3d(0, 0, 0);
    }
    &.is-star .filled-stars {
      right: .06em;
    }
    &.is-heart .empty-stars {
      margin-right: .07em;
    }
  }
  
  .rating-lg {
    font-size: 3.91em;
  }
  
  .rating-md {
    font-size: 3.13em;
  }
  
  .rating-sm {
    font-size: 2.5em;
  }
  
  .rating-xs {
    font-size: 2em;
  }
  
  .rating-xl {
    font-size: 4.89em;
  }
  
  .rating-container .clear-rating {
    color: #aaa;
    cursor: not-allowed;
    display: none;
    vertical-align: middle;
    font-size: 60%;
    padding-right: 5px;
  }
  
  .clear-rating-active {
    cursor: pointer !important;
    &:hover {
      color: #843534;
    }
  }
  
  .rating-container .caption {
    color: #999;
    display: inline-block;
    vertical-align: middle;
    font-size: 60%;
    margin-top: -.6em;
    margin-left: 5px;
    margin-right: 0;
    position: absolute;
    right: 5px;
    top: 48%;
  }
  
  .rating-rtl .caption {
    margin-right: 5px;
    margin-left: 0;
  }
  
  @media print {
    .rating-container .clear-rating {
      display: none;
    }
  }
  .empty-star{
    color:#dee2e6;
  }
  .rating-container .filled-stars {
      -webkit-text-stroke: 0; 
      text-shadow: none; 
      color:#ffca00;
  }
  .progress{
      margin-top: 1rem;
      border-radius: 0; 
  }
  .progress-bar {
      background-color: #ffca00;
  }

#addHero-logo, #addHero-avatar{
    visibility: hidden;
}

#hero-avatar-form{
    width: 100%;
    height: 100%;
    background-color: #F7F7F7;
    border-radius: 100%;
    -o-object-fit: cover;
    object-fit: cover;  
}

#hero-logo-form{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: #F7F7F7;
    -o-object-fit: cover;
    object-fit: cover;

    .filepond--root, .filepond--root .filepond--image-preview-wrapper{
        border-radius: 10px;
    }
}

#hero-avatar-form .hero--logo, #hero-logo-form .hero--logo{
    max-height: 155px;
}

#hero-avatar-form .filepond--processing-complete-indicator,
#hero-logo-form .filepond--processing-complete-indicator{
    visibility: hidden;
}

.profile #hero-avatar-form img, #hero-avatar-form .filepond--root .filepond--image-preview-wrapper{
    cursor: pointer;
}

#hero-avatar-form .filepond--drop-label.filepond--drop-label label,
#hero-logo-form .filepond--drop-label.filepond--drop-label label{
    padding: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.hero--logo-static{
    display: none;
}

.show-socials-button{
    text-align: left;
    cursor: pointer;
}

// .companyTypeItem{
//     &:last-child{
//         .single-company.single-section {
//             padding-bottom: 50px;
//         }
//     }
// }