/* You can add global styles to this file, and also import other style files */
@import "./assets/styles/variables";
@import "./assets/styles/global";
@import "./assets/styles/modals";
@import "./assets/styles/custom_material";
@import "~angular-notifier/styles";
@import '~ngx-bar-rating/themes/br-stars-theme';

.star {
    --br-font-size: 34px !important;
    --br-active-color: gold !important;
    --br-inactive-color: transparent !important;
    -webkit-text-stroke: 1px gold !important;
}

/* Firefox */
*  {
        scrollbar-width: auto;
        scrollbar-color: #5E5E5E;
    }

    /* Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
        width: 8px;
    }

    *::-webkit-scrollbar-track {
        background: transparent;
    }

    *::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 4px;
    }

// input.star:checked ~ label.star:before {
//     color: pink !important;
// }

// label.star:before {
//     font-size: 1.975rem;
// }

// div.stars {
//     width: auto !important;
// }
  
// .star{
//     font-size: 40px;
//     .br-default.br-unit::after{
//             -webkit-text-stroke: 1px black !important;
//     }
//     .br-default .br-selected::after{
//         -webkit-text-stroke: 1px red !important;
//     }
// }
.br{
    width: fit-content;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 5px 15px;
}
.br-stars .br-unit{
    background-image: url('/assets/img/empty-star.svg');
    filter: grayscale(0);
    
}
.br-stars .br-selected, .br-stars .br-active{
    background-image: url('/assets/img/full-star.svg');
}

body {
    margin: 0;
    padding: 0;
}

p {
    margin: 0;
}

.app {
    .app-body {
        display: block;
        position: relative;
    }
}

html:not([dir=rtl]) .sidebar {
    margin-left: unset;
}

html,
body {
    height: 100%;
}

.cdk-global-scrollblock{
    overflow-y: hidden !important;
}

body {
    margin: 0;
}

header.app-header.navbar {
    background: $body-bg;
    border-bottom: none;
    z-index: 1020;
}

label {
    margin-bottom: 0;
}

.view-all-brands, .view-all-people, .view-all-companies{
    cursor: pointer;
}