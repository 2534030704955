@import '~@angular/material/theming';
@include mat-core();

$mat-blue-custom: (
    50: #abc8ea,
    100: #97bae2,
    200: #83addf,
    300: #6ea1da,
    400: #5b92d3,
    500: #4684cd,
    600: #3578c6,
    700: #306cb2,
    800: #295f9d,
    900: #245389,
    A100: #bfd5ed,
    A200: #d2e2f2,
    A400: #e7eff9,
    A700: #1e4775,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $dark-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    )
);

$my-app-primary: mat-palette($mat-blue-custom);
$my-app-accent: mat-palette($mat-pink, 500, 900, A100);
$my-app-warn: mat-palette($mat-deep-orange);
$my-app-theme: mat-light-theme($my-app-primary, $my-app-accent, $my-app-warn);

@include angular-material-theme($my-app-theme);

.mat-error {
    color: $nice-pink;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label{
    color: $nice-pink;
}

.mat-form-field.mat-warn .mat-input-element, .mat-form-field-invalid .mat-input-element{
    caret-color: $nice-pink;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-ripple, .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent{
    background-color: $nice-pink;
}

.mat-checkbox {
    font-family: $monserrat;
}


.mat-form-field{
    font-family: $monserrat;
    font-weight: $f-weight-500;
}

.mat-raised-button{
    font-family: $monserrat;
    font-size: $f-size-3;
    line-height: $f-line-h;
    font-weight: $f-weight-500; 
}

.mat-autocomplete-panel{
    min-width: 180px !important;
    // position: absolute !important;
    // top: 0;
    // left: auto;
    // right: auto;
}

mat-icon{
    cursor: pointer;
    color: $icon-grey;
}


/* Modal */

.cdk-overlay-container{
    z-index: 1021;
} 

.cdk-global-overlay-wrapper {
    // pointer-events: auto;
    display: block;
    position: relative;
    overflow: auto;
    text-align: center;
}

.cdk-overlay-connected-position-bounding-box,
.cdk-global-overlay-wrapper,
.cdk-overlay-container{
z-index: 9990 !important;
}

.mat-drawer-transition .mat-drawer-backdrop{
    height: fit-content;
}
  
// .cdk-global-overlay-wrapper::before {
//     content: '';
//     display: inline-block;
//     height: 100%;
//     white-space: nowrap;
// }

::ng-deep .cdk-overlay-connected-position-bounding-box {
    z-index: 10000 !important;
    }
    
    ::ng-deep .cdk-global-overlay-wrapper {
    z-index: 1000 !important;
    }
  
// .cdk-overlay-pane {
//     display: inline-block;
//     position: relative;
//     text-align: left;
//     white-space: normal;
// }

.add-modalbox{
    padding-bottom: 0;
    position: absolute !important;
    top: 0;
    left: calc(50% - 335px);
    z-index: 999991;
    .mat-dialog-container{
        margin: 50px 0;
        padding: 3.6rem;
        border-radius: 10px;
    }
    .mat-dialog-content{
        max-height: 100%;
        overflow-y: hidden;
    }

    .mat-form-field-wrapper{
        padding-top: 1rem !important;
    }

    input:-internal-autofill-selected {
        background-color: transparent;
    }
}

.edit-modalbox{
    padding-bottom: 0;
    position: absolute !important;
    top: 0;
    left: calc(50% - 335px);
    z-index: 999999 !important;
    .mat-dialog-container{
        margin: 50px 0;
        padding: 3.6rem;
        border-radius: 10px;
    }
    .mat-dialog-content{
        max-height: 100%;
        overflow-y: hidden;
    }

    .mat-form-field-wrapper{
        padding-top: 1rem !important;
    }

    input:-internal-autofill-selected {
        background-color: transparent;
    }
}

.all-list-modalbox{
    padding-bottom: 0;
    position: absolute !important;
    top: 0;
    left: calc(50% - 335px);
    // overflow-y: auto;
    // overflow-x: hidden;
    z-index: 999990;
    .mat-dialog-container{
        margin: 3.125rem 0;
        padding: 3.6rem;
        border-radius: 0.625rem;
    }

    .mat-dialog-content{
        max-height: 100%;
        overflow-y: hidden;
    }

    .mat-form-field-wrapper{
        padding-top: 1rem !important;
    }

    input:-internal-autofill-selected {
        background-color: transparent;
    }

    .all-list-dialog-header{
        position: relative;
        display: flex;
        justify-content: center;
        padding-bottom: 2.5rem;
        .mat-dialog-title{
            text-align: center;
            font-family: $monserrat;
            font-size: 1.125rem;
            font-weight: 600;
            line-height: 1.515rem;
            padding-top: 0.625rem;
            pointer-events: none;
        }
        .close{
            position: absolute;
            right: 0;
            font-size: 2.5rem;
            font-weight: $f-weight-300;
            &:focus{
                outline: none;
            }
        }
    }

    .all-data{
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 2.5rem;

        .single-data{
            width: 50%;
            max-width: 50%;
            flex: 1 1 50%;
            margin: 0.93rem 0;
            display: flex;
            .data-logo{
                width: 3.75rem;
                height: 3.75rem;
                border-radius: 0.625rem;
                background-color: $body-bg;
                border: 0.031rem solid rgba(0, 0, 0, 0.15);
                overflow: hidden;
                cursor: pointer;
                img{
                    width: 100%;
                    height: 100%;
                    background-color: $white;
                    -o-object-fit: cover;
                    object-fit: cover;
                }
            }
            .data-logo.avatar{
                border-radius: 100%;
            }
            .data-name{
                margin: auto 0;
                padding-left: 0.938rem;
                max-width: 65%;
                text-align: left;
                cursor: pointer;
                h3{
                    font-family: $monserrat;
                    font-size: 1.125rem;
                    font-weight: 400;
                    line-height: 1.371rem;
                    margin-bottom: 0;
                }
                .data-position{
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 142.69%;
                    color: $gray;
                }
            }
        }
    }
}

.small-modalbox{
    max-width: 500px !important;
    width: 420px;
    position: absolute !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .mat-dialog-container{
        display: contents;
    }
}

.mat-icon{
    color: unset;
}

.select_country{
    width: 100%;
    .mat-form-field{
        width: 100%;
    }
    .mat-input-element,.mat-form-field-label, .input, .error{
        padding-left: 0.625rem;
    }
    // .mat-icon{
    //     svg{
    //         border-radius: 100%;
    //     }
    // }

}
// .mat-option .mat-option-text .mat-icon svg{
//     border-radius: 100%;
// }
